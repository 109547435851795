import React from "react";
import { images } from "../constants";
import ReactPlayer from "react-player";

const ShortFilm = () => {
	return (
		<div className='h-fit w-full flex flex-col items-center justify-start bg-[#eff0ed]'>
			<div className='flex flex-col md:w-8/12 xl:w-6/12 mt-14 ml-10 sm:ml-20 md:ml-0 mb-10'>
				<div className='flex-grow border-t border-[#b6b8af] mb-10'></div>

				<img
					src={images.heading_shortfilm}
					alt='Abstracto logo'
					className='hidden md:block'
				/>
				<img
					src={images.heading_shortfilm}
					alt='Abstracto logo'
					className='inline-block md:hidden max-w-full'
				/>
				<div className='mr-10 sm:mr-20 md:mr-0 text-sm sm:text-[15px] mt-4'>
					<p className='leading-normal'>
						Santiago has always been infatuated with the sea, swimming, and all
						things water. You will notice a recurring aquatic theme in the
						collection, as some of the abstract artworks seem to resemble
						fish-like figures.
						<br />
						<br />
						To build on this theme and to celebrate the release of the NFT
						collection, a short film was made. Titled{" "}
						<strong className='italic'>The Squid & The Mermaid</strong>, the
						short film is non-verbal to mirror Santiago’s own communication
						style. The story is open to interpretation, and it is intended to
						celebrate those who see things differently.
						<br />
						<br />
						The short film stars Santiago as himself, and model Gracie Grace as
						the mermaid. Gracie’s appearance in the film is made even more
						meaningful by the fact that she has a real-life sibling with Down
						syndrome.
						<br />
						<br />
						Watch the 2-minute film, and see Santiago’s art come to life.
					</p>
					<div className='relative pt-[60.24%] mt-4 '>
						<ReactPlayer
							url='https://youtu.be/BhtO9L_oGDs'
							className='absolute top-0 left-0'
							controls={true}
							playing={false}
							muted={true}
							width='100%'
							height='100%'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShortFilm;
