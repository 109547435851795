import { useEffect } from "react";
import { RecoilRoot } from "recoil";
import { TransactionContextProvider } from "./hooks/useTx";
import { ConfettiProvider } from "./hooks";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "animate.css";
import { WOW } from "wowjs";
import { config } from "./config";

import { images } from "./constants";

import Navigation from "./components/Navigation";
import Home from "./components/Home";
import About from "./components/About";
import Collection from "./components/Collection";
import Footer from "./components/Footer";
import Mint from "./components/Mint";
import ShortFilm from "./components/ShortFilm";
import Gallery from "./components/Gallery";
import SEO from "./components/SEO";
import NavigationGallery from "./components/NavigationGallery";
import ScrollToTop from "./components/ScrollToTop";

const Main = () => {
	const date = new Date().toISOString();

	return (
		<div className='max-h-fit'>
			<Navigation />
			{Date.parse(config.launchDate) <= Date.parse(date) && <Mint />}
			<Home />
			<About />
			<div className='h-4 bg-[#b6b8af] w-full'></div>
			<Collection />
			<ShortFilm />
			<Footer />
		</div>
	);
};

const MainGallery = () => {
	return (
		<>
			<NavigationGallery />
			<Gallery />
		</>
	);
};

function App() {
	useEffect(() => {
		new WOW({ live: false }).init();
	}, []);

	// if mint active, show mint page
	return (
		<RecoilRoot>
			<HelmetProvider>
				<TransactionContextProvider>
					<ConfettiProvider>
						<BrowserRouter>
							<div className='font-arimo'>
								<SEO
									title='Abstracto'
									description='Santiago is the first artist with Down syndrome and autism to release an NFT collection of 1,000 unique artworks with his art collection: ABSTRACTO.'
									type='summary_large_image'
									image={images.thumbnail}
								/>
								<ScrollToTop />
								<Routes>
									<Route path='/' element={<Main />} />
									<Route path='gallery' element={<MainGallery />} />
								</Routes>
							</div>
						</BrowserRouter>
					</ConfettiProvider>
				</TransactionContextProvider>
			</HelmetProvider>
		</RecoilRoot>
	);
}

export default App;
