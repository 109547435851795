import { useState } from "react";

import { Input, Button, Card } from "../lib/Primitives";

import { formatEther } from "ethers/lib/utils";
import { ethers } from "ethers";
import { useMintQuery, useMintAdminState } from "../queries";
import { signWhitelist, usePriorityProvider } from "../lib";
import { useContracts } from "../hooks";

export default function AdminPanel() {
	const signer = usePriorityProvider()?.getSigner();
	const { erc721, handleTx, handleTxError } = useContracts();

	const [baseURIInput, setBaseURIInput] = useState();
	const [unrevealedURIInput, setUnrevealedURIInput] = useState();
	const [revealTimeInput, setRevealTimeInput] = useState();
	const [maxMintInput, setMaxMintInput] = useState();
	const [withdrawalAddressInput, setWithdrawalAddressInput] = useState();
	const [maxPriceInput, setMaxPriceInput] = useState();
	const [minPriceInput, setMinPriceInput] = useState();
	const [decIntervalInput, setDecIntervalInput] = useState();
	const [decAmountInput, setDecAmountInput] = useState();
	const [airdropAddress, setAirdropAddress] = useState();
	const [airdropAmount, setAirdropAmount] = useState();
	const [ownerInput, setOwnerInput] = useState();

	const [
		{
			owner,
			maxSupply,
			price,
			startingPrice,
			minimumPrice,
			auctionStart,
			auctionActive,
			totalSupply,
			maxMint,
		},
		updateMintState,
	] = useMintQuery();
	const [
		{ name, symbol, baseURI, balance, decrementAmount, decrementInterval },
		updateAdminInfo,
	] = useMintAdminState();

	const callContractFunction = (someFunction, someValue, update) => {
		return someFunction(...someValue)
			.then(handleTx)
			.then(update)
			.catch(handleTxError);
	};

	const callContractNoArg = (someFunction, update) => {
		someFunction().then(handleTx).then(update).catch(handleTxError);
	};

	return (
		<div className='h-screen'>
			<Card className='w-[500px] pb-5'>
				<p className='text-gray-200 text-center'>Admin Panel</p>
				<Input label='Contract Address' value={erc721?.address} />
				<Input label='Owner' value={owner} />
				<div className='flex'>
					<Input className='w-full mr-2' label='Name' value={name} />
					<Input className='w-full ml-2' label='Symbol' value={symbol} />
				</div>
				<div className='flex flex-row'>
					<Input className='w-full' label='Max Supply' value={maxSupply} />
					<Input
						className='w-full ml-2'
						label='Current Price'
						value={ethers.utils.formatEther(price.toString()) + " ETH"}
					/>
					<Input
						className='w-full ml-2'
						label='Starting Price'
						value={ethers.utils.formatEther(startingPrice.toString()) + " ETH"}
					/>
					<Input
						className='w-full ml-2'
						label='Minimum Price'
						value={ethers.utils.formatEther(minimumPrice.toString()) + " ETH"}
					/>
				</div>
				<div className='flex flex-row items-end'>
					<Input className='w-full' label='Max Mint' value={maxMint} />
					<Input
						className='w-full ml-2'
						label='Minted Supply'
						value={totalSupply}
					/>
					<Input
						className='w-full ml-2'
						label='Decrement Amount'
						value={
							ethers.utils.formatEther(decrementAmount.toString()) + " ETH"
						}
					/>
					<Input
						className='w-full ml-2'
						label='Decrement Interval'
						value={decrementInterval / 60 + " minutes"}
					/>
				</div>
				<Input
					className='w-full'
					label='Auction start'
					value={new Date(auctionStart * 1000)}
				/>
				<Input label='Auction active' value={auctionActive ? "live" : "paused"}>
					{auctionActive ? (
						<Button
							onClick={() => {
								callContractNoArg(
									erc721.connect(signer).stopAuction(),
									updateAdminInfo
								);
							}}
							className='text-white bg-[#8b514c] hover:ring-1 ring-white ml-2'
						>
							Stop
						</Button>
					) : (
						<Button
							onClick={() => {
								callContractNoArg(
									erc721.connect(signer).startAuction(),
									updateMintState
								);
							}}
							className='text-white bg-[#8b514c] hover:ring-1 ring-white ml-2'
						>
							Start
						</Button>
					)}
				</Input>
				<Input
					label='Balance'
					value={" Ξ " + parseFloat(formatEther("" + balance)).toFixed(4)}
				>
					<Button
						onClick={() =>
							erc721
								.connect(signer)
								.withdraw()
								.then(handleTx)
								.then(updateAdminInfo)
								.catch(handleTxError)
						}
						className='text-white bg-[#8b514c] hover:ring-1 ring-white ml-2'
						disabled={!signer}
					>
						withdraw
					</Button>
				</Input>
				<Input
					label='Base URI'
					value={baseURIInput ?? baseURI}
					placeholder={baseURI ?? "ipfs://QYGFW../"}
					onChange={(event) => setBaseURIInput(event.target.value)}
					className='text-black'
				>
					<Button
						onClick={() =>
							callContractFunction(
								erc721.connect(signer).setBaseURI,
								[baseURIInput],
								updateAdminInfo
							)
						}
						className='text-white bg-[#8b514c] hover:ring-1 ring-white ml-2'
						disabled={!signer || !baseURIInput}
					>
						{"set"}
					</Button>
				</Input>
				<Input
					label='Set max mint per wallet'
					value={maxMintInput ?? maxMint}
					placeholder={"amount"}
					onChange={(event) => setMaxMintInput(event.target.value)}
					className='text-black'
				>
					<Button
						onClick={() =>
							callContractFunction(
								erc721.connect(signer).setMaxMint,
								[maxMintInput],
								updateMintState
							)
						}
						className='text-white bg-[#8b514c] hover:ring-1 ring-white ml-2'
						disabled={!signer || !maxMintInput}
					>
						{"set"}
					</Button>
				</Input>
				<Input
					label='Transfer ownership to address'
					value={ownerInput}
					placeholder={"0x000..."}
					onChange={(event) => setOwnerInput(event.target.value)}
					className='text-black'
				>
					<Button
						onClick={() =>
							callContractFunction(
								erc721.connect(signer).transferOwnership,
								[ownerInput],
								updateMintState
							)
						}
						className='text-white bg-[#8b514c] hover:ring-1 ring-white ml-2'
						disabled={!signer || !ownerInput}
					>
						{"set"}
					</Button>
				</Input>
				<h2 className='mt-2 font-bold'>Set decrement function</h2>
				<div className='flex'>
					<Input
						label='Starting price'
						value={
							maxPriceInput ??
							ethers.utils.formatEther(startingPrice.toString())
						}
						placeholder={"In ETH"}
						onChange={(event) => setMaxPriceInput(event.target.value)}
						className='text-black'
					/>
					<Input
						label='Minimum price'
						value={
							minPriceInput ?? ethers.utils.formatEther(minimumPrice.toString())
						}
						placeholder={"In ETH"}
						onChange={(event) => setMinPriceInput(event.target.value)}
						className='text-black'
					/>
				</div>
				<div className='flex'>
					<Input
						label='Decrement amount'
						value={
							decAmountInput ??
							ethers.utils.formatEther(decrementAmount.toString())
						}
						placeholder={"In ETH"}
						onChange={(event) => setDecAmountInput(event.target.value)}
						className='text-black'
					/>
					<Input
						label='Decrement interval'
						value={decIntervalInput ?? decrementInterval}
						placeholder={"In seconds"}
						onChange={(event) => setDecIntervalInput(event.target.value)}
						className='text-black'
					/>
				</div>
				<Button
					onClick={() =>
						callContractFunction(
							erc721.connect(signer).setDecrementFunction,
							[
								ethers.utils.parseEther(maxPriceInput),
								ethers.utils.parseEther(minPriceInput),
								decIntervalInput,
								ethers.utils.parseEther(decAmountInput),
							],
							updateMintState
						)
					}
					className='text-white bg-[#8b514c] hover:ring-1 ring-white self-center mt-2'
					disabled={
						!signer ||
						!maxPriceInput ||
						!minPriceInput ||
						!decAmountInput ||
						!decIntervalInput
					}
				>
					{"set decrement function"}
				</Button>

				{/* </details> */}
				<Button
					onClick={() => {
						updateMintState();
						updateAdminInfo();
					}}
					className='text-white bg-[#8b514c] hover:ring-1 ring-white self-center mt-2'
				>
					Fetch state
				</Button>
			</Card>
		</div>
	);
}
