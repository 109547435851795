const { _contractConfig: configOverrides } = window;

export const config = {
	maxSupply: 1000,
	purchaseLimit: 20,
	erc721Address: "0xbdea23678d92E281056E4de1D3DBB3F15C7dB4D8", // Change to correct address
	chainId: 1,
	validChainname: "Ethereum Mainnet",
	links: {
		opensea: "https://opensea.io/",
		discord: "https://discord.com/",
		twitter: "https://twitter.com/home",
		instagram: "https://www.instagram.com/",
	},
	launchDate: "2022-12-09T23:00:00.000Z", //"2022-12-09T10:00:00.000Z",
	...configOverrides,
};
