import React, { useState, useRef } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";

const Flip = ({ img, back, backAlt }) => {
	const [clicked, setClicked] = useState(false);
	const ref = useRef();
	return (
		<Flippy
			flipOnHover={false} // default false
			flipOnClick={true} // default false
			flipDirection='horizontal' // horizontal or vertical
			ref={ref} // to use toggle method like ref.curret.toggle()
		>
			<FrontSide
				style={{
					padding: "0px",
				}}>
				<img src={img} alt='painting' />
			</FrontSide>
			<BackSide
				style={{ padding: "0px" }}
				className=' text-center flex items-center'>
				<img src={back} alt={backAlt} />
			</BackSide>
		</Flippy>
	);
};

export default Flip;
