import React, { useState, useRef } from "react";

import { useMintQuery } from "../queries";
import { Button, Modal } from "../lib/Primitives";
import AdminPanel from "./AdminPanel";
import hamburger from "../assets/images/hamburgerIcon.png";
import abstracto from "../assets/images/AbstractoLogoWhite.png";
import Link from "react-scroll/modules/components/Link";
import { Link as LinkRouter } from "react-router-dom";

const Navigation = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [height, setHeight] = useState("0px");
	const [adminModalOpen, setAdminModalOpen] = useState();
	const [{ isContractOwner, loaded }] = useMintQuery();

	const contentSpace = useRef(null);

	const toggleMenu = () => {
		setMenuOpen((prevState) => !prevState);
		setHeight(menuOpen ? "0px" : `${contentSpace.current.scrollHeight}px`);
	};

	return (
		<div className='relative z-10 top-0 right-0 bg-black h-16 pt-2  w-full'>
			<div className='flex flex-row justify-between items-center w-10/12 xl:w-8/12 h-full mx-auto max-w-[1600px]'>
				<LinkRouter to='/' className='h-full hidden sm:block'>
					<img
						src={abstracto}
						alt='logo'
						className='p-3 object-contain max-h-full '
					/>
				</LinkRouter>
				<div className='block sm:hidden'></div>
				<button className='h-8 w-8' onClick={toggleMenu}>
					<img src={hamburger} alt='menu' />
				</button>
			</div>
			<LinkRouter to='/' className=''>
				<img
					src={abstracto}
					alt='logo'
					className='object-contain max-h-full block sm:hidden'
				/>
			</LinkRouter>
			<div className='relative w-10/12 xl:w-8/12 mx-auto'>
				<div
					ref={contentSpace}
					style={{ maxHeight: `${height}` }}
					className='absolute top right-0 bg-[#1c1c1c] shadow-lg text-white transition-max-height duration-700 ease-in-out overflow-hidden pl-5 pr-5'
				>
					<div className='flex flex-col justify-center mb-1 divide-y divide-solid divide-[#b6b8af] items-center pb-2 pt-2 text-md sm:text-lg'>
						{isContractOwner && (
							<div className='m-8'>
								<Button
									className='uppercase bg-[#8b514c] rounded-full hover:ring-2 ring-white font-poppins font-bold text-white'
									onClick={() => setAdminModalOpen(true)}
								>
									Admin Panel
								</Button>
								{adminModalOpen && (
									<>
										<Modal
											open={adminModalOpen}
											onClose={() => setAdminModalOpen(false)}
										>
											<AdminPanel />
										</Modal>
									</>
								)}
							</div>
						)}

						{/* <img
                  src={item.icon}
                  alt={item.text}
                  className="p-1 mb-1 h-8 w-8 hover:ring-1 ring-white rounded-sm"
                /> */}
						<Link
							to='about'
							smooth={true}
							className='uppercase p-2 cursor-pointer hover:ring-2 ring-white'
						>
							Minting info
						</Link>
						<Link
							to='collection'
							smooth={true}
							className='uppercase p-2 cursor-pointer hover:ring-2 ring-white'
						>
							collection
						</Link>
						<LinkRouter
							to='/gallery'
							className='uppercase p-2 cursor-pointer hover:ring-2 ring-white'
						>
							Art samples
						</LinkRouter>
						<a
							href='https://twitter.com/santiagoartwork?s=21&t=Uo-t2s5M35YdSrp5r8lqjQ'
							target='_blank'
							rel='noreferrer'
							className='uppercase p-2 cursor-pointer hover:ring-2 ring-white'
						>
							Twitter
						</a>
						<a
							href='https://opensea.io/'
							target='_blank'
							rel='noreferrer'
							className='uppercase p-2 cursor-pointer hover:ring-2 ring-white'
						>
							Opensea
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navigation;
