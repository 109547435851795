import React, { useState } from "react";
import { images } from "../constants";
import content from "../content";
import { IoIosArrowBack } from "react-icons/io";

const Gallery = () => {
	const limit = 5;
	const [lowerBound, setLowerBound] = useState(0);
	const [upperBound, setUpperBound] = useState(5);

	const [selected, setSelected] = useState(0);

	const onLeftClick = () => {
		if (lowerBound === 0) {
			setLowerBound(content.gallery.length - limit);
			setUpperBound(content.gallery.length);
			return;
		}

		setLowerBound((prevState) => prevState - limit);
		setUpperBound((prevState) => prevState - limit);
	};

	const onRightClick = () => {
		if (upperBound === content.gallery.length) {
			setLowerBound(0);
			setUpperBound(limit);
			return;
		}

		setLowerBound((prevState) => prevState + limit);
		setUpperBound((prevState) => prevState + limit);
	};
	return (
		<div className='min-h-screen h-fit w-full flex flex-col items-center justify-start bg-[#eff0ed] sm:pt-5 sm:pb-5'>
			<div className='h-14 w-full bg-black sm:hidden'></div>
			<img
				src={content.gallery[selected].img}
				alt='selected card'
				className='max-h-[80vh] p-2'
			/>
			<div className='flex flex-row sm:max-h-[20vh]'>
				<button
					className='p-2 hover:scale-105'
					onClick={() => {
						onLeftClick();
					}}
				>
					<IoIosArrowBack className='h-10 w-10' />
				</button>
				{content.gallery
					.filter((el, idx) => idx >= lowerBound && idx < upperBound)
					.map((item, index) => {
						return (
							<button
								key={index}
								className={`p-2`}
								onClick={() => {
									setSelected(item.index);
								}}
							>
								<img
									src={item.img}
									alt='High Resolution Art'
									className='hover:ring-2 ring-white sm:max-h-[20vh]'
								/>{" "}
							</button>
						);
					})}
				<button
					className='p-2 rotate-180 hover:scale-105'
					onClick={() => {
						onRightClick();
					}}
				>
					<IoIosArrowBack className='h-10 w-10' />
				</button>
			</div>
		</div>
	);
};

export default Gallery;
