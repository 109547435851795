import React from "react";
import collection from "../assets/images/TheCollection.png";
import { images } from "../constants";
import content from "../content";
import Flip from "./Flip";
import { Link } from "react-router-dom";

const Collection = () => {
	return (
		<div className='h-fit w-full flex flex-col items-center justify-start bg-[#eff0ed]'>
			<div className='flex flex-col md:w-8/12 xl:w-6/12 mt-14 ml-10 sm:ml-20 md:ml-0'>
				<img
					src={collection}
					alt='Minting header'
					className='hidden md:block'
				/>
				<img
					src={images.collectionMobile}
					alt='Minting header'
					className='inline-block md:hidden max-w-full'
				/>
				<div className='mt-4 mb-6 leading-normal mr-10 sm:mr-20 md:mr-0 text-sm sm:text-[15px]'>
					<p>
						The full <strong>ABSTRACTO</strong> collection consists of 1,000
						unique NFT artworks. As a collection preview, below you will find a
						curated selection of 30 <strong>ABSTRACTO</strong> artworks. These
						will be some of the very first NFTs up for grabs in the Dutch
						auction, as they are all placed within the first 100 artworks.{" "}
						<br />
						<br />
						<span className='text-highlight'>
							<span className='font-bold'>Flipping Tiles:</span> Tap on each
							artwork below to discover more of Santiago’s creative process.
							<br />
							<br /> <span className='font-bold'>High Resolution:</span> To view
							the sample artworks larger,{" "}
							<Link to='/gallery' className='underline'>
								click here
							</Link>
							.
						</span>
					</p>
				</div>
				<div className='grid grid-cols-2 md:grid-cols-3 gap-y-7 gap-x-8 content-center align-middle mb-12 mr-10 sm:mr-20 md:mr-0'>
					{content.collection.cards.map((item, index) => (
						<Flip
							key={index}
							img={item.img}
							back={item.back}
							backAlt={item.backAlt}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default Collection;
