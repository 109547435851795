import React, { useState } from "react";
import { Link } from "react-scroll";
import ReactPlayer from "react-player";
import { images } from "../constants";

const Home = () => {
	return (
		<div
			id='home'
			className='flex flex-col h-fit items-center justify-between bg-black'
		>
			<div className='w-full md:w-10/12 xl:w-8/12 mb-10 h-full'>
				<script src='https://player.vimeo.com/api/player.js'></script>
				<div className='relative pt-[60.24%] mt-20 md:mt-4 '>
					<ReactPlayer
						url='https://player.vimeo.com/video/773191769?h=1bd3da0a07&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
						className='absolute top-0 left-0'
						playsinline
						controls={true}
						playing={true}
						muted={true}
						loop={true}
						width='100%'
						height='100%'
					/>
				</div>
			</div>

			<Link
				to='about'
				className='h-10 w-10 p-2 mb-5 mt-0 cursor-pointer animate-float'
				smooth={true}
			>
				<img src={images.downArrow} alt='down arrow' />
			</Link>
		</div>
	);
};

export default Home;
