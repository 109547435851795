import React from "react";
import { images } from "../constants";
import abstracto from "../assets/images/AbstractoLogo.png";

const Abstracto = () => {
	return (
		<div className='bg-[#eff0ed] w-full'>
			<div
				id='collection'
				className='h-fit w-full flex flex-col items-center bg-paperShort md:bg-paper bg-cover'
			>
				<div className='md:w-8/12 xl:w-6/12 mt-44 sm:mt-56 mb-20 ml-10 sm:ml-20 md:ml-0'>
					<img
						src={abstracto}
						alt='Abstracto logo'
						className='hidden md:block'
					/>
					<img
						src={images.abstractoMobile}
						alt='Abstracto logo'
						className='inline-block md:hidden max-w-full'
					/>
					<div className='mr-10 sm:mr-20 md:mr-0 text-sm sm:text-[15px] leading-normal'>
						<h4 className='mt-12 mb-2 font-bold text-[18px] text-highlight'>
							About the Artist
						</h4>
						<p className=''>
							Santiago is the first artist with Down syndrome and autism to
							release an NFT collection of 1,000 unique artworks. At age 16,
							Santiago is non-verbal, but his art speaks loud and clear. Art has
							always been Santiago's purest form of expression, so it was only
							natural for him to produce a large-scale body of work.
							<br />
							<br />
							The <strong>ABSTRACTO</strong> collection offers a profound view
							into how the young artist sees the world. There is a true sense of
							immediacy and minimalism on display, and viewers are invited to
							interpret the abstract paintings.
						</p>
						<h4 className='mt-10 mb-2 font-bold text-[18px] text-highlight'>
							Making of the Collection
						</h4>
						<p className=''>
							Over the course of 5 months, Santiago painted over 200 paintings
							in his abstract expressionist style; hence the project name
							<strong> ABSTRACTO</strong>. He used a diverse range of materials,
							including oil paint, acrylic paint, gesso, oil pastel, and
							charcoal. <br />
							<br /> Santiago’s canvas paintings were all scanned to digital
							format. Next, following an algorithmic editing technique,
							different elements of the paintings were combined in brand new
							ways. As such, Santiago’s 200 physical paintings yielded the 1,000
							unique digital artworks that comprise the complete{" "}
							<strong> ABSTRACTO</strong> collection.
						</p>
						<h4 className='mt-10 mb-2 font-bold text-[18px] text-highlight'>
							Why <strong>ABSTRACTO</strong> Matters
						</h4>
						<p className=''>
							<strong>ABSTRACTO</strong> is a landmark project, as Santiago is
							the first artist with Down syndrome and autism to release an NFT
							collection of 1,000 unique artworks.
							<br />
							<br />
							There is a need for more inclusivity and representation for people
							with disabilities in the fine art world. Santiago’s project
							highlights what anyone with special needs is truly capable of when
							their talent is nurtured.
							<br />
							<br />
							Santiago embraced what makes him different. Instead of looking at
							his disability as a weakness, he turned it into his greatest
							strength. Santiago’s uniqueness is <i>exactly</i> what gives him a
							distinct artistic perspective, and what makes his art special.
							<br />
							<br />
							Be part of Santiago’s story, and join him as he unveils the most
							important art project of his life.
							<br />
							<br />
							Santiago is the main beneficiary of his landmark project.
							Furthermore, <strong>ABSTRACTO</strong> champions the Down
							syndrome and autism communities by pledging donations to the{" "}
							<i>National Down Syndrome Society</i> (NDSS), and the{" "}
							<i>National Autism Association</i> (NAA).
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Abstracto;
