import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Card } from "../lib/Primitives";
import { images } from "../constants";

const MintSuccess = ({ onClose, tx }) => {
  return (
    <Card className="min-w-[300px] max-w-[400px] pb-5 bg-[#eff0ed] flex flex-col pl-10 pr-10 font-poppins">
      <button onClick={onClose} className="self-end mb-4">
        <AiOutlineCloseCircle className="w-8 h-8 text-[#494750]" />
      </button>
      <h2 className="text-3xl font-bold text-[#494750] mb-4 self-center text-center">
        MINTING SUCCESS
      </h2>
      <p className="mb-4">
        Congratulations on becoming a collector of{" "}
        <strong>
          <i>Abstracto</i>
        </strong>
      </p>
      <p className="mb-4">
        Check your wallet or OpenSea to see your NFT. Refresh homepage to mint
        more NFTs.
      </p>
      <p className="mb-4">Click to view transaction</p>
      <a
        href={tx}
        target="_blank"
        rel="noreferrer"
        className="w-2/3 self-center hover:ring-1 ring-white cursor-pointer rounded-full"
      >
        <img src={images.etherscan} alt="etherscan" />
      </a>
    </Card>
  );
};

export default MintSuccess;
