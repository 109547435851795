import walletlink from "../assets/images/WalletLink.svg";
import walletconnect from "../assets/images/WalletConnect.svg";
import volumeIcon from "../assets/images/volumeIcon.png";
import playIcon from "../assets/images/playIcon.png";
import pauseIcon from "../assets/images/pauseIcon.png";
import TheCollection from "../assets/images/TheCollection.png";
import mintingScreen from "../assets/images/mintingScreen.png";
import Minting from "../assets/images/Minting.png";
import mintingBackground from "../assets/images/mintingBackground.png";
import MetaMask from "../assets/images/MetaMask.svg";
import metamask from "../assets/images/metamask.png";
import hamburgerIcon from "../assets/images/hamburgerIcon.png";
import DutchAuction from "../assets/images/DutchAuction.png";
import fullscreenIcon from "../assets/images/fullscreenIcon.png";
import downArrow from "../assets/images/downArrow.png";
import AbstractoLogo from "../assets/images/AbstractoLogo.png";
import AbstractoLogoWhite from "../assets/images/AbstractoLogoWhite.png";
import clock from "../assets/images/clock.png";
import art1 from "../assets/images/art1.png";
import art2 from "../assets/images/art2.png";
import art3 from "../assets/images/art3.png";
import art4 from "../assets/images/art4.png";
import art5 from "../assets/images/art5.png";
import art6 from "../assets/images/art6.png";
import art7 from "../assets/images/art7.png";
import art8 from "../assets/images/art8.png";
import art9 from "../assets/images/art9.png";
import art10 from "../assets/images/art10.png";
import art11 from "../assets/images/art11.png";
import art12 from "../assets/images/art12.png";
import art13 from "../assets/images/art13.png";
import art14 from "../assets/images/art14.png";
import art15 from "../assets/images/art15.png";
import art16 from "../assets/images/art16.png";
import art17 from "../assets/images/art17.png";
import art18 from "../assets/images/art18.png";
import art19 from "../assets/images/art19.png";
import art20 from "../assets/images/art20.png";
import art21 from "../assets/images/art21.png";
import art22 from "../assets/images/art22.png";
import art23 from "../assets/images/art23.png";
import art24 from "../assets/images/art24.png";
import art25 from "../assets/images/art25.png";
import art26 from "../assets/images/art26.png";
import art27 from "../assets/images/art27.png";
import art28 from "../assets/images/art28.png";
import art29 from "../assets/images/art29.png";
import art30 from "../assets/images/art30.png";
import discord from "../assets/logos/discord.svg";
import opensea from "../assets/logos/opensea.svg";
import twitter from "../assets/logos/twitter.svg";
import mintingMobile from "../assets/images/mintingMobile.png";
import abstractoMobile from "../assets/images/abstractoMobile.png";
import collectionMobile from "../assets/images/collectionMobile.png";
import etherscan from "../assets/images/etherscan.png";
import paintings from "../assets/images/paintings.png";
import back1 from "../assets/images/1.png";
import back2 from "../assets/images/2.png";
import back3 from "../assets/images/3.png";
import back4 from "../assets/images/4.png";
import back5 from "../assets/images/5.png";
import back6 from "../assets/images/6.png";
import back7 from "../assets/images/7.png";
import back8 from "../assets/images/8.png";
import back9 from "../assets/images/9.png";
import back10 from "../assets/images/10.png";
import back11 from "../assets/images/11.png";
import back12 from "../assets/images/12.png";
import back13 from "../assets/images/13.png";
import back14 from "../assets/images/14.png";
import back15 from "../assets/images/15.png";
import back16 from "../assets/images/16.png";
import back17 from "../assets/images/17.png";
import back18 from "../assets/images/18.png";
import back19 from "../assets/images/19.png";
import back20 from "../assets/images/20.png";
import back21 from "../assets/images/21.png";
import back22 from "../assets/images/22.png";
import back23 from "../assets/images/23.png";
import back24 from "../assets/images/24.png";
import back25 from "../assets/images/25.png";
import back26 from "../assets/images/26.png";
import back27 from "../assets/images/27.png";
import back28 from "../assets/images/28.png";
import back29 from "../assets/images/29.png";
import back30 from "../assets/images/30.png";
import heading_shortfilm from "../assets/images/heading_shortfilm.png";
import thumbnail from "../assets/images/thumbnail.jpeg";
import hdart1 from "../assets/images/hdart1.png";
import hdart2 from "../assets/images/hdart2.png";
import hdart3 from "../assets/images/hdart3.png";
import hdart4 from "../assets/images/hdart4.png";
import hdart5 from "../assets/images/hdart5.png";
import hdart6 from "../assets/images/hdart6.png";
import hdart7 from "../assets/images/hdart7.png";
import hdart8 from "../assets/images/hdart8.png";
import hdart9 from "../assets/images/hdart9.png";
import hdart10 from "../assets/images/hdart10.png";
import hdart11 from "../assets/images/hdart11.png";
import hdart12 from "../assets/images/hdart12.png";
import hdart13 from "../assets/images/hdart13.png";
import hdart14 from "../assets/images/hdart14.png";
import hdart15 from "../assets/images/hdart15.png";
import hdart16 from "../assets/images/hdart16.png";
import hdart17 from "../assets/images/hdart17.png";
import hdart18 from "../assets/images/hdart18.png";
import hdart19 from "../assets/images/hdart19.png";
import hdart20 from "../assets/images/hdart20.png";
import hdart21 from "../assets/images/hdart21.png";
import hdart22 from "../assets/images/hdart22.png";
import hdart23 from "../assets/images/hdart23.png";
import hdart24 from "../assets/images/hdart24.png";
import hdart25 from "../assets/images/hdart25.png";
import hdart26 from "../assets/images/hdart26.png";
import hdart27 from "../assets/images/hdart27.png";
import hdart28 from "../assets/images/hdart28.png";
import hdart29 from "../assets/images/hdart29.png";
import hdart30 from "../assets/images/hdart30.png";

const gallery = [
	hdart1,
	hdart2,
	hdart3,
	hdart4,
	hdart5,
	hdart6,
	hdart7,
	hdart8,
	hdart9,
	hdart10,
	hdart11,
	hdart12,
	hdart13,
	hdart14,
	hdart15,
	hdart16,
	hdart17,
	hdart18,
	hdart19,
	hdart20,
	hdart21,
	hdart22,
	hdart23,
	hdart24,
	hdart25,
	hdart26,
	hdart27,
	hdart28,
	hdart29,
	hdart30,
];

export default {
	walletlink,
	walletconnect,
	volumeIcon,
	playIcon,
	pauseIcon,
	TheCollection,
	mintingScreen,
	mintingBackground,
	Minting,
	metamask,
	MetaMask,
	hamburgerIcon,
	DutchAuction,
	fullscreenIcon,
	downArrow,
	AbstractoLogo,
	AbstractoLogoWhite,
	art1,
	art2,
	art3,
	art4,
	art5,
	art6,
	art7,
	art8,
	art9,
	art10,
	art11,
	art12,
	art13,
	art14,
	art15,
	art16,
	art17,
	art18,
	art19,
	art20,
	art21,
	art22,
	art23,
	art24,
	art25,
	art26,
	art27,
	art28,
	art29,
	art30,
	clock,
	discord,
	twitter,
	opensea,
	mintingMobile,
	abstractoMobile,
	collectionMobile,
	etherscan,
	paintings,
	back1,
	back2,
	back3,
	back4,
	back5,
	back6,
	back7,
	back8,
	back9,
	back10,
	back11,
	back12,
	back13,
	back14,
	back15,
	back16,
	back17,
	back18,
	back19,
	back20,
	back21,
	back22,
	back23,
	back24,
	back25,
	back26,
	back27,
	back28,
	back29,
	back30,
	heading_shortfilm,
	thumbnail,
	gallery,
};
