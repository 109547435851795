import React from "react";

const Footer = () => {
	return (
		<div className='flex flex-col items-center justify-star bg-black'>
			<div className='flex flex-col w-11/12 md:w-8/12 xl:w-6/12 text-white'>
				<div className='self-end p-5 text-sm text-right'>
					<h2 className='font-bold'>More Resources</h2>
					<p>
						<a
							href='https://ndss.org/'
							target='_blank'
							rel='noreferrer'
							className='hover:underline'
						>
							National Down Syndrome Society
						</a>
					</p>
					<p>
						<a
							href='https://nationalautismassociation.org/'
							target='_blank'
							rel='noreferrer'
							className='hover:underline'
						>
							National Autism Association
						</a>
					</p>
				</div>
				<p className='self-center p-5 text-xs '>
					<strong>ABSTRACTO</strong> All Rights Reserved 2022
				</p>
			</div>
		</div>
	);
};

export default Footer;
