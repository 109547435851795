import React from "react";
import mint from "../assets/images/Minting.png";
import { images } from "../constants";

const Minting = () => {
	return (
		<div className='h-fit w-full flex flex-col items-center justify-start bg-[#eff0ed]'>
			<div className='flex flex-col md:w-8/12 xl:w-6/12 mt-14 ml-10 sm:ml-20 md:ml-0'>
				<img src={mint} alt='Minting header' className='hidden md:block' />
				<img
					src={images.mintingMobile}
					alt='Minting header'
					className='inline-block md:hidden w-full'
				/>
				<div className='flex flex-col mr-10 sm:mr-20 md:mr-0 mt-8 text-sm sm:text-[15px]'>
					<table className='table-auto mb-2 border-separate'>
						<tbody>
							<tr className='mb-4'>
								<td className='text-[#96897c] align-top'>Launch Date</td>
								<td>
									December 9th, 2022 6pm EST{" "}
									<a
										href='https://www.addevent.com/event/vV15542527'
										target='_blank'
										rel='noreferrer'
										className='underline'
									>
										(Click to add to calendar)
									</a>
								</td>
							</tr>
							<tr className='h-1'>
								<td></td>
							</tr>
							<tr className='mb-2'>
								<td className='text-[#96897c] align-top'>Supply</td>
								<td>1,000 NFTs (max 20 per transaction)</td>
							</tr>
							<tr className='h-1'>
								<td></td>
							</tr>
							<tr className='mb-2'>
								<td className='text-[#96897c] align-top'>Structure</td>
								<td>Dutch auction format</td>
							</tr>
							<tr className='h-1'>
								<td></td>
							</tr>
							<tr className='mb-2'>
								<td className='text-[#96897c] align-top'>Price</td>
								<td>
									Starting price 5.0<span className='font-sans'>Ξ</span>
									<br />
									Drops by 0.2<span className='font-sans'>Ξ</span> every 5
									minutes, until it rests at 0.2
									<span className='font-sans'>Ξ</span> or sells out.
								</td>
							</tr>
						</tbody>
					</table>

					<h2 className='text-lg sm:text-xl text-highlight mb-2 mt-2'>
						Exclusive Physical Paintings
					</h2>
					<p>
						The first 22 NFTs minted include both the digital artwork NFT, and
						also the original physical painting on canvas that matches the
						digital NFT artwork. These are the most exclusive NFTs in the
						<strong> ABSTRACTO</strong> collection. The physical paintings on
						canvas are signed with Santiago’s initial, and they were produced in
						2022.
					</p>
					<p className='mt-5'>Out of the first 22 NFTs minted:</p>
					<ul className='list-disc'>
						<li className='ml-10'>
							NFT numbers 1 through 6 are accompanied by physical pantings that
							measure 36" x 48". You can see these 6 large paintings in the
							photo below.
						</li>
						<li className='ml-10'>
							NFT numbers 7 through 22 are accompanied by paintings that measure
							11" x 14".
						</li>
					</ul>
					<p className='mt-2'>
						The original physical pantings will be shipped worldwide to minters
						for their private collections (shipping paid separately).
					</p>
					<img
						src={images.paintings}
						alt='Santiago surrounded by his paintings'
						className='mt-5 hidden sm:inline-block'
					/>
				</div>
			</div>
			<img
				src={images.paintings}
				alt='Santiago surrounded by his paintings'
				className='mt-5 w-full sm:hidden inline-block'
			/>
		</div>
	);
};

export default Minting;
