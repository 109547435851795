import React from "react";
import Minting from "./Minting";
import Abstracto from "./Abstracto";

const About = () => {
  return (
    <div id="about" className="justify-center items-center bg-light">
      <Minting />
      <Abstracto />
    </div>
  );
};

export default About;
