// eslint-disable-next-line import/no-anonymous-default-export
import { images } from "../constants";

export default {
	nav: {
		links: [
			{ text: "Discord", to: "home", icon: images.discord },
			{ text: "Twitter", to: "about", icon: images.twitter },
			{ text: "Opensea", to: "collection", icon: images.opensea },
		],
	},
	minting: {
		titles: [
			"Starting Time:",
			"Starting Price:",
			"Price Drops By:",
			"Price Drops:",
			"Max Mint Quantity:",
		],
		descriptions: [
			"6pm EST on 3/28/2022",
			"5.0Ξ",
			"0.2Ξ until sold out or rests at 0.4Ξ",
			"Every 5 minutes",
			"10 per transaction, 20 per wallet",
		],
	},
	about: {
		titles: ["Launch Date", "Supply", "Structure", "Price"],
		descriptions: [
			"March 28th, 2022 6pm EST (Click to add to calendar)",
			"1,000 NFTs (max 10 per transaction, 20 per wallet address)",
			"Dutch auction format",
			"Starting price 5.0Ξ",
			"Drops by 0.2Ξ every 5 minutes, until it rests at 0.4Ξ or sells out.",
		],
		links: {
			calendar: "",
		},
	},
	aboutRow: [{ title: "", description: "" }],
	collection: {
		cards: [
			{
				img: images.art1,
				back: images.back1,
				backAlt: "alt",
				index: 0,
			},
			{
				img: images.art2,
				back: images.back2,
				backAlt: "alt",
				index: 1,
			},
			{
				img: images.art3,
				back: images.back3,
				backAlt: "alt",
				index: 2,
			},
			{
				img: images.art4,
				back: images.back4,
				backAlt: "alt",
				index: 3,
			},
			{
				img: images.art5,
				back: images.back5,
				backAlt: "alt",
				index: 4,
			},
			{
				img: images.art6,
				back: images.back6,
				backAlt: "alt",
				index: 5,
			},
			{
				img: images.art7,
				back: images.back7,
				backAlt: "alt",
				index: 6,
			},
			{
				img: images.art8,
				back: images.back8,
				backAlt: "alt",
				index: 7,
			},
			{
				img: images.art9,
				back: images.back9,
				backAlt: "alt",
				index: 8,
			},
			{
				img: images.art10,
				back: images.back10,
				backAlt: "alt",
				index: 9,
			},
			{
				img: images.art11,
				back: images.back11,
				backAlt: "alt",
				index: 10,
			},
			{
				img: images.art12,
				back: images.back12,
				backAlt: "alt",
				index: 11,
			},
			{
				img: images.art13,
				back: images.back13,
				backAlt: "alt",
				index: 12,
			},
			{
				img: images.art14,
				back: images.back14,
				backAlt: "alt",
				index: 13,
			},
			{
				img: images.art15,
				back: images.back15,
				backAlt: "alt",
				index: 14,
			},
			{
				img: images.art16,
				back: images.back16,
				backAlt: "alt",
				index: 15,
			},
			{
				img: images.art17,
				back: images.back17,
				backAlt: "alt",
				index: 16,
			},
			{
				img: images.art18,
				back: images.back18,
				backAlt: "alt",
				index: 17,
			},
			{
				img: images.art19,
				back: images.back19,
				backAlt: "alt",
				index: 18,
			},
			{
				img: images.art20,
				back: images.back20,
				backAlt: "alt",
				index: 19,
			},
			{
				img: images.art21,
				back: images.back21,
				backAlt: "alt",
				index: 20,
			},
			{
				img: images.art22,
				back: images.back22,
				backAlt: "alt",
				index: 21,
			},
			{
				img: images.art23,
				back: images.back23,
				backAlt: "alt",
				index: 22,
			},
			{
				img: images.art24,
				back: images.back24,
				backAlt: "alt",
				index: 23,
			},
			{
				img: images.art25,
				back: images.back25,
				backAlt: "alt",
				index: 24,
			},
			{
				img: images.art26,
				back: images.back26,
				backAlt: "alt",
				index: 25,
			},
			{
				img: images.art27,
				back: images.back27,
				backAlt: "alt",
				index: 26,
			},
			{
				img: images.art28,
				back: images.back28,
				backAlt: "alt",
				index: 27,
			},
			{
				img: images.art29,
				back: images.back29,
				backAlt: "alt",
				index: 28,
			},
			{
				img: images.art30,
				back: images.back30,
				backAlt: "alt",
				index: 29,
			},
		],
	},
	gallery: images.gallery.map((item, index) => ({ img: item, index: index })),
};
